import { Alert, Checkbox, Collapse } from "antd";
import { useState } from "react";

const { Panel } = Collapse;


const RegisterForm = ({
  handleSubmit,
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  repassword,
  setRePassword,
}) => { 
  const [agreedTOC, setAgreedTOC] = useState(false);
  const [activeKey, setActiveKey] = useState(1);

  function handleClick(value) {
    if (value && value.target) {
      setAgreedTOC(value.target.checked);
      setActiveKey(0);
    } else {
      setActiveKey(1-activeKey);
    }
  }

  return (
  <form onSubmit={handleSubmit} className="mt-3">
    <div className="form-group mb-3">
      <label className="form-label">Your name</label>
      <input
        type="text"
        className="form-control"
        placeholder="Enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </div>

    <div className="form-group mb-3">
      <label className="form-label">Email address</label>
      <input
        type="email"
        className="form-control"
        placeholder="any fake email will do."
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>

    <div className="form-group mb-3">
      <label className="form-label">Password</label>
      <input
        type="password"
        className="form-control"
        placeholder="Enter password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>

    <div className="form-group mb-3">
      <label className="form-label">Reenter Password</label>
      <input
        type="password"
        className="form-control"
        placeholder="Reenter password"
        value={repassword}
        onChange={(e) => setRePassword(e.target.value)}
      />
    </div>
    <Collapse defaultActiveKey="1" activeKey={activeKey} onChange={handleClick} accordion>
      <Panel header="Terms and Conditions" key="1">
      <p> <b>
        TL;DR. The tours you download are suggested paths. It is your responsibility to be aware and adjust for any dangerous or undesirable situations or changes to any path or POI indicated on the tour. We will accept no liability if things don't go the way you wanted. No refunds. 
      </b></p>
      <p>
        We strive to make sure that all our tours reach the highest level of customer satisfaction, there will be no refunds for the purchase of your tour for any reason. Availability, weather, ability and safety all affect any unguided tour. What may have allowed free and safe passage at the time of creation may change at any time and outside of our control, therefore, we have a no refund policy. 
      </p>
      <p>
        We may not be held responsible should the tour be unsafe, unnavigatable, illegal or otherwise insufficient. It is the tour designers responsibility to have planned a safe and viable tour at the time of upload. Legal, environmental or political changes may affect the tour at any time. Walkingtour.com will accept no liability for changes.
      </p>
      <p>
        Walkingtour.com reserves the right to change, remove or modify tours without notice, including changing or altering venues and routes. Downloading the tour to your device will always download the latest version available.
      </p>
      <p>
        Walkingtour.com accepts no liability for participants, venues or the publics behaviour. 
      </p>
      <p>
        Walkingtour.com accepts no liability for comments made in uploaded content. This includes tourist reviews and the tours themselves. If you notice any illegal content posted, please contact us.
      </p>
      <p>
        The participant agrees to indemnify Walkingtour.com (it’s employees, directors, suppliers, agents, distributors, representatives, etc) against any third party claims as a result of the participant’s behaviour and actions. Any damages or loss caused by a participant is solely the participant’s responsibility; 
      </p>
      <p>
        As in all human endeavours, there is a level of risk. Walkingtour.com, it's contractors, partners and employees assume no liability or responsibility for any injury, loss, damage or inconvenience sustained by any person using its services, nor to their property. 
      </p>
      <div style={{flex:"1 1 auto",  width: "18%"}}> &nbsp;
        <Checkbox
          onChange={(value) => {handleClick(value)} }
          checked = {agreedTOC}
        > I agree</Checkbox>
      </div>
      </Panel>
    </Collapse>

    <br />
    <button disabled={!email || password !== repassword || password.length < 8 || !agreedTOC } className="btn btn-primary">
      Submit
    </button> <br></br>
    {(password !== repassword) ? (
    <Alert  style={{margin:"1em 0 0 0"}}  type="error" message="Passwords do not match" />
    ) : ""}
    {(password.length < 8) ? (
    <Alert  style={{margin:"1em 0 0 0"}}  type="warning" message="Password needs to be 8 characters or more" />
    ) : ""}
    {(!agreedTOC) ? (
    <Alert  style={{margin:"1em 0 0 0"}}  type="error" message="You must agree to the Terms and Conditions" />
    ) : ""}
    <br></br>
  </form>
)};

export default RegisterForm;
