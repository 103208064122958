
const TourImageEditForm = ({
  handleImageChange,
  handleImageChange2,
  handleImageChange3,
  handleSubmit,
  preview1,
  preview2,
  preview3
}) => {

  return ( <>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label  style={{width:"300px", height:"130px"}} className="btn btn-outline-secondary btn-block m-2 text-left">
          Image
          <img style={{maxHeight:"105px", maxWidth:"100px"}}
              width="100px" height="105px"
              src={preview1}
              alt="preview_image"
              className="img img-fluid m-2"
            />

          <input
            type="file"
            name="image"
            onChange={handleImageChange}
            accept="image/jpeg, image/jpg"
            hidden
          />
        </label>

        <label  style={{width:"300px", height:"130px"}} className="btn btn-outline-secondary btn-block m-2 text-left">
          Image 2
          <img style={{maxHeight:"105px", maxWidth:"100px"}}
              width="100px" height="105px"
              src={preview2}
              alt="preview_image"
              className="img img-fluid m-2"
            />
          <input
            type="file"
            name="image2"
            onChange={handleImageChange2}
            accept="image/jpeg, image/jpg"
            hidden
          />
        </label>

        <label  style={{width:"300px", height:"130px"}} className="btn btn-outline-secondary btn-block m-2 text-left">
          Image 3
          <img  style={{maxHeight:"105px", maxWidth:"100px"}}
              width="100px" height="105px"
              src={preview3}
              alt="preview_image"
              className="img img-fluid m-2"
            />
          <input
            type="file"
            name="image3"
            onChange={handleImageChange3}
            accept="image/jpeg, image/jpg"
            hidden
          />
        </label>

      </div>
      <p>&nbsp;
    </p>
      <button className="btn btn-outline-primary m-2">Save</button>

    </form>
</>  
  );
};

export default TourImageEditForm;
