import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";


const TopNav = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({ ...state }));
  const history = useHistory();

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    window.localStorage.removeItem("auth");
    history.push("/login");
  };

  return (
    <div className="header-div">
    <div className="row">
      <div className="col-md-1">
        <img alt="logo" width="64" src="/logo192.png" />
      </div>
      <div className="col-md-1">
          <Link className="nav-link" to="/">
          <h4 className="text-center blue-nav-text"><HomeOutlined /></h4>
          </Link>
      </div>
      <div className="col-md-1">
        {auth !== null && (
          <Link className="nav-link" to="/dashboard">
            <h6 className="text-center blue-nav-text">Dashboard</h6>
          </Link>
        )}
      </div>
      <div className="col-md-1">
        {auth !== null && (
          <a className="nav-link pointer" href="/login" onClick={logout}>
            <h6 className="text-center blue-nav-text">Logout</h6>
          </a>
        )}
        {auth === null && (
          <>
            <Link className="nav-link" to="/login">
              <h6 className="text-center blue-nav-text">Login</h6>
            </Link>
          </>
        )}
      </div>
      <div className="col-md-1">
        {auth !== null && (
          <></>
        )}
        {auth === null && (
          <>
            <Link className="nav-link" to="/register">
              <h6 className="text-center blue-nav-text">Register</h6>
            </Link>
          </>
        )}
      </div>
    </div>
    </div>
  );
};

export default TopNav;
