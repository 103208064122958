import { useState } from "react";
import { toast } from "react-toastify";
import { addTour } from "../actions/hotel";
import { useSelector } from "react-redux";
import GPX from 'gpx-parser-builder';
import { Alert, Checkbox } from "antd";
import * as xml2js from 'isomorphic-xml2js';

// add new data here

const NewSite = () => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  // state
  const [overrideCompass, setOverrideCompass] = useState(false);
  const [route, setRoute] = useState("");

  let lastPointGPX = {};

  const getDirection = (lat1, lng1, lat2, lng2, bearing) => {
    const teta = Math.atan2(lng2 - lng1, lat2 - lat1);
    const directionPM = Math.round((teta) * (180/Math.PI));
    const direction = (directionPM < 0) ? 360 + directionPM : directionPM;
    return direction; //direction in degree
  }

/*  console.log("Should be north(ish)", getDirection(43.8841014643404, -78.95722567104288, 43.905501839488004, -78.94568144438041));
  console.log("Should be north(ish)", getDirection(43.89075124834927, -78.96323381874826, 43.903553857647246, -78.97911249482677));
  console.log("Should be south(ish)", getDirection(43.90389398605398, -78.96503626305987, 43.88045149991312, -78.95889936933224));
  console.log("Should be south(ish)", getDirection(43.89075124834927, -78.96323381874826, 43.87918323958706, -78.9652937551044));
  console.log("Should be west(ish)", getDirection(43.89749306205573, -78.96267591931849, 43.898328004212345, -79.00207220212945));
  console.log("Should be east(ish)", getDirection(43.89749306205573, -78.96267591931849, 43.89823523343977, -78.92413794332258)); */

  const getCompassGPX = (trkpt) => {
    let heading = 0;
    if (!overrideCompass && trkpt.extensions && trkpt.extensions.compass && trkpt.extensions.compass.length) {
      heading = trkpt.extensions.compass * 1.0;
    } else {
      if (lastPointGPX.$ && lastPointGPX.$.lat) {
        heading = getDirection(lastPointGPX.$.lat, lastPointGPX.$.lon, trkpt.$.lat, trkpt.$.lon)
      }  
    }
    lastPointGPX = trkpt;
    return heading;
  }

  const getCompassWT = (trkpt) => {
    const heading = getDirection(lastPointGPX.La, lastPointGPX.Lo, trkpt.La, trkpt.Lo)
    lastPointGPX = trkpt;
    return heading;
  }

  const CalcDist2Points = (lat1, lon1, lat2, lon2, isMetric) => {    // return miles or meters.
    let dist = 0;
  if ((lat1 !== lat2) || (lon1 !== lon2)) {
    const radlat1 = Math.PI * lat1/180;
    const radlat2 = Math.PI * lat2/180;
    const theta = lon1-lon2;
    const radtheta = Math.PI * theta/180;
    dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (isMetric) { 
      dist = dist * 1.609344 * 1000;  // meters
    }
  }
    return dist;
}

const timeStampParse = function (stamp) {
  let ret = new Date(stamp);
  if (isNaN(ret)) {
    if (stamp.length > 0) {
      let stmp = stamp.replace(' ', 'T');
      ret = new Date(stmp);
    }
  }
  if (ret.getFullYear() < 1970) {
    ret = new Date(stamp * 1000 * 1000);
  }
  if (ret.getFullYear() === 1970) {
    ret = new Date(stamp * 1000);
  }
  if (isNaN(ret)) {
    ret = new Date("1970-01-01T" + stamp);
  }
  if (isNaN(ret)) {
    ret = new Date("1970-01-01T0" + stamp);
    if (!isNaN(ret)) {
      ret.setTime(ret.getTime() + 1000*60*60*12);
    }
  }
  if (isNaN(ret)) {
    ret = stamp;
  }
  return ret;
}


  const handleSubmit = async (e) => {
    e.preventDefault();
    let tourList = [];

    let tour = {
      id: (new Date()).getTime(),
      n:"",
      t:0, b:0, l:0, r:0,
      f:0, c:0,
      z:17,
      tilePattern: "/tour/tile/{0}-{1}-{2}.png",
      filePattern: "/tour/{1}",
      path: []
    };
    let i = 0;
    let j = 0;
    let k = 0;
    try {
      ///////////////////
      // GPX
      ///////////////////
      const gpx = GPX.parse(route);
      if (gpx.wpt && gpx.wpt.length > 0) {
        toast.warn("Unsupported GPX Waypoints ignored.");
      }
      if (gpx.trk && gpx.trk.length === 0) {
        toast.error("No GPX Tracks found.");
        return;
      }
      for (i = 0; i < gpx.trk.length; i++) {
        const trk = gpx.trk[i];
        if (!tour.n || tour.n.length === 0) 
          tour.n = (trk.name && trk.name.length) ? trk.name : "";
        if (trk.trkseg && trk.trkseg.length > 0) {
          for (j = 0; j < trk.trkseg.length; j++) {
            const trkseg = trk.trkseg[j];
            if (trkseg.trkpt && trkseg.trkpt.length > 0) {
              for (k = 0; k < trkseg.trkpt.length; k++) {
                const trkpt = trkseg.trkpt[k];
                let pt = {
                  O: 0,
                  La: trkpt.$.lat * 1.0,
                  Lo: trkpt.$.lon * 1.0,
                  A: trkpt.ele * 1.0,
                  D: getCompassGPX(trkpt),
                  N: "",
                  P: "",
                  TS: trkpt.time
                };  
                tour.path.push(pt);
              }
            }
          }
        }
      }
    } 
    catch (e) {      
      try {
        let kml;
        xml2js.parseString(route, {
          explicitArray: false
        }, (err, xml) => {
          if (err) {
            throw(err);
          }
          if (!xml.kml) {
            throw(err);
          }
          kml = xml.kml;
          if (kml && kml.Document && kml.Document.Placemark) {
            const placemark = kml.Document.Placemark;
            if (placemark['MultiGeometry'] && placemark['MultiGeometry']['LineString'] && placemark['MultiGeometry']['LineString'].coordinates) {
              const coordgroups = placemark['MultiGeometry']['LineString'].coordinates.split(" ");
              let i = 0;
              for (i = 0; i < coordgroups.length; i++) {
                var coord = coordgroups[i].split(",");
                let pt = {
                  O: 0,
                  La: coord[1] * 1.0,
                  Lo: coord[0] * 1.0,
                  A: (coord.length > 2) ? coord[2] * 1.0 : 0,
                  D: 0,
                  N: "",
                  P: "",
                  TS: (coord.length > 3) ? timeStampParse(coord[3]) : 0,
                };  
                pt.D = getCompassWT(pt);
                tour.path.push(pt);
              }
              tour.n = (placemark.name && placemark.name.length) ? placemark.name : "";  
            }
            if (tour.path.length === 0) {
              if (placemark['gx:Track'] && placemark['gx:Track']['gx:coord']) {
                for(var i = 0; i < placemark['gx:Track']['gx:coord'].length; i++){
                  let results = placemark['gx:Track']['gx:coord'][i];
                  let coord = results.split(" ");
                  let when = (coord.length > 3) ? timeStampParse(coord[3]) : 0;
                  if (placemark['gx:Track']['when'] && placemark['gx:Track']['when'].length > i) {
                    when = placemark['gx:Track']['when'][i];
                  } 

                  // As I said before you have to split the returned value.
                  let pt = {
                    O: 0,
                    La: coord[1] * 1.0,
                    Lo: coord[0] * 1.0,
                    A: (coord.length > 2) ? coord[2] * 1.0 : 0,
                    D: 0,
                    N: "",
                    P: "",
                    TS: when,
                  };  
                  pt.D = getCompassWT(pt);
                  tour.path.push(pt);
                  console.log("pt", pt);
                }
                tour.n = (placemark.name && placemark.name.length) ? placemark.name : "";  
              }
            }
          }
        });
      }
      catch(e) {
        try {
          ///////////////////
          // JSON - Native or GeoJSON
          ///////////////////
          const jsonTrack = JSON.parse(route);
  //        console.log(jsonTrack);

          if (jsonTrack && jsonTrack.features && jsonTrack.features.length > 0 && jsonTrack.features[0].geometry && jsonTrack.features[0].geometry.coordinates.length > 0) {
            ///////////////////
            // GeoJSON
            ///////////////////
            for (j = 0; j < jsonTrack.features.length; j++) {
              const feature = jsonTrack.features[j];
  //            console.log("feature", feature);
              if (!tour.n || tour.n.length === 0) 
              tour.n = (feature.properties && feature.properties.title && feature.properties.title.length) ? feature.properties.title : "";  
              const coord = feature.geometry.coordinates;
              for (k = 0; k < coord.length; k++) {
                const trkptl = coord[k];
                for (i = 0; i < trkptl.length; i++) {
                  const trkpt = trkptl[i];
  //                console.log("trkpt", trkpt);
                  let pt = {
                    O: 0,
                    La: trkpt[1] * 1.0,
                    Lo: trkpt[0] * 1.0,
                    A: trkpt[2] * 1.0,
                    D: getCompassGPX(trkpt),
                    N: "",
                    P: "",
                    TS: timeStampParse(trkpt[3]),
                  };  
                  tour.path.push(pt);
                }
              }
            }
            if (!tour.n || tour.n.length === 0) 
              tour.n = Date.now.toString();  
          } else
          if (jsonTrack.length > 0) {
            ///////////////////
            // Native Multi?
            ///////////////////
            if (jsonTrack[0].n && jsonTrack[0].n.length > 0 && jsonTrack[0].path && jsonTrack[0].path.length > 0)
              tourList = jsonTrack;
          } else {
            ///////////////////
            // Native Single
            ///////////////////
            if (jsonTrack.n && jsonTrack.n.length > 0 && jsonTrack.path && jsonTrack.path.length > 0)
              tour = jsonTrack;
            else {
              toast.error("This does't seem to be a valid GPX, GeoJSON, or KML format that I understand.");
              return;
            }
          }
        } 
        catch(e) {
          toast.error("This does't seem to be a valid GPX, GeoJSON, or KML format that I understand.");
          return;
        }
      }
      if (tourList.length === 0) {
        if (!tour.path || !tour.path.length) {
          toast.error("Unable to extract any track points from track file. ");
          return;
        }
        tour.lastUpdate = tour.path[tour.path.length - 1].TS;

        let lastpt = tour.path[0];    
        tour.dist = 0;
        for (i = 1; i < tour.path.length; i++) {
          const pt = tour.path[i];
          // top/bottom
          tour.dist += CalcDist2Points(pt.La, pt.Lo, lastpt.La, lastpt.Lo);
          lastpt = pt;
        }
        let wp1 = timeStampParse(tour.path[0].TS);
        let wp2 = timeStampParse(tour.path[tour.path.length-1].TS);
        tour.time = Math.floor((wp2 - wp1) / 1000 / 60);
      }
    }
    if (tourList.length === 0)
      tourList.push(tour);
    console.log("Tour", tourList);
    try {
      let res = await addTour(token, {recorded: tourList});
      console.log("TOUR CREATE RES", res);
      toast.success("Your New Tour is Being Processed");
      setTimeout(() => {
        window.location = "/dashboard/seller";
      }, 5000);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data);
    }
  };

  const localMessage = `Use our mobile Record Tour tool (${process.env.REACT_APP_WEBSERVER}/tour/record.html) to record and upload directly from your device.`;  

  return (
    <>
      <div style={{backgroundImage:"url(/backgrounds/newsite.jpg)", backgroundSize:"100% 110%"}} className="container-fluid bg-secondary p-5 text-center">
        <h2 className="page-title">Add Walking Tour</h2>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10">
          <br />
          <h4>Upload a GPX or GeoJSON or an exported track to use as a walking tour</h4>
          <Alert  style={{margin:"0 0 1em 0"}}  type="error" message={localMessage} />
            <p>Only basic track information is converted, you'll need to add POI and comments later.</p>
            <br />
            <div>
              <Checkbox 
                onChange={(value) => setOverrideCompass(value.target.checked)}
                checked = {overrideCompass}
              > Ignore compass data and recalculate direction based on movement</Checkbox>
            </div>
              <textarea
                        name="route"
                        onChange={(ob) => setRoute(ob.target.value)}
                        placeholder="GPX Format Route Recording (eg from Garmin, Gaia, OSMTracker)"
                        className="form-control m-2"
                        rows="5" cols="60" 
                        value={route}
                      />
              <button onClick={handleSubmit} className="btn btn-outline-primary m-2">Save</button>
          </div>
          <div className="col-md-2">
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSite;
