import { useState } from "react";
import { currencyFormatter } from "../../actions/stripe";
import { storageTypeString, durationStringMinutes, setQueueDownload } from "../../actions/hotel";
import { TypeFeatures } from "../Helper";
import OrderModal from "../modals/OrderModal";
import { Image, Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { DownloadOutlined, InfoOutlined } from "@ant-design/icons";


const BookingCard = ({ hotel, session, orderedBy, updatedAt, createdAt, queued }) => {
  const [showModal, setShowModal] = useState(false);
  const [curQueued, setCurQueued] = useState(queued);
  console.log(hotel);
  const { auth } = useSelector((state) => ({ ...state }));
  return (
    <>
      <div className="card mb-3">
        <div className="row no-gutters">
          <div className="col-md-4">
          <p>
            {hotel.image && hotel.image.contentType ? (
              <Image
                src={`${process.env.REACT_APP_API}/tour/image/${hotel._id}`}
                alt="default site"
                className="card-image img img-fluid"
                style={{maxHeight:"305px", maxWidth:"300px"}}
                width="300px" 
              />
            ) : (
              <img
                src="https://via.placeholder.com/900x500.png?text=stashling.com"
                alt="default site"
                className="card-image img img-fluid"
              />
            )}
          </p>
          <p>
            {hotel.image2 && hotel.image2.contentType ? (
              <Image
                src={`${process.env.REACT_APP_API}/tour/image2/${hotel._id}`}
                alt="default site"
                className="card-image img img-fluid"
                style={{maxHeight:"305px", maxWidth:"300px"}}
                width="300px" 
              />
            ) : (<></>)}
          </p>
          <p>
            {hotel.image3 && hotel.image3.contentType ? (
              <Image
                src={`${process.env.REACT_APP_API}/tour/image3/${hotel._id}`}
                alt="default site image"
                className="card-image img img-fluid"
                style={{maxHeight:"305px", maxWidth:"300px"}}
                width="300px" 
              />
            ) : (<></>)}
          </p>
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h3 className="card-title">
                {hotel.title}{" "}
                <span className="float-right text-primary">
                  {currencyFormatter({
                    amount: hotel.price * 100,
                    currency: "cad",
                  })}
                </span>{" "}
              </h3>
              <p className="alert alert-info">{hotel.location}</p>
              <p className="card-text">{`${hotel.content.substring(
                1,
                200
              )}...`}</p>
              <p className="card-text">
                  in this <span className="float-right text-primary"> {storageTypeString(hotel.layout)}{" "}
                </span>
                available for <span className="float-right text-primary">
                  {durationStringMinutes(hotel.duration)}{" "}
                </span>
              </p>

              Features
              <p>
                {TypeFeatures(hotel)}
              </p>
              {showModal && (
                <OrderModal
                  session={session}
                  orderedBy={orderedBy}
                  showModal={showModal}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  setShowModal={setShowModal}
                />
              )}

              <div className="d-flex justify-center h4">
              <Button type="primary" href={`/tour/${hotel._id}`}>Tour Details</Button>
              <div>&nbsp; &nbsp; </div>
              <Button type="primary" onClick={() => setShowModal(!showModal)}>Show Payment info</Button>
              <div>&nbsp; &nbsp; </div>
              <div>&nbsp; &nbsp; </div>
              <div>&nbsp; &nbsp; </div>
              <Button type="primary" danger={curQueued} icon={<DownloadOutlined />} onClick={() => {setCurQueued((curQueued) ? false : true); setQueueDownload(auth.token, hotel._id)} }>
                {!curQueued ? (<>Download to Device</>) : (<>Remove from Download</>)}
              </Button>
              <div>&nbsp; </div>
              <Tooltip title="Select the tours you want your phone to download for this trip. Tour data can be large, you may wish to download using Wi-Fi.">
                <Button type="primary" size="small" shape="circle" icon={<InfoOutlined style={{position:"absolute", margin:"-0.7em 0 0 -0.5em"}}  />} />
              </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingCard;
