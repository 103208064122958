import axios from "axios";

export const createSite = async (token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/create-site`, data, {
    headers: { 
      Authorization: `Bearer ${token}`,
    },
  });

export const allSites = async () =>
  await axios.get(`${process.env.REACT_APP_API}/tours`);

export const diffDays = (from, to) => {
  const day = 24 * 60 * 60 * 1000;
  const start = new Date(from);
  const end = new Date(to);
  const difference = Math.round(Math.abs((start - end) / day));
  return difference;
};

export const sellerTours = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/seller-tours`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteTour = async (token, hotelId) =>
  await axios.delete(`${process.env.REACT_APP_API}/delete-tour/${hotelId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const read = async (hotelId) =>
  await axios.get(`${process.env.REACT_APP_API}/tour/${hotelId}`);

export const updateTour = async (token, data, hotelId) => 
  await axios.put(
    `${process.env.REACT_APP_API}/update-tour/${hotelId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  export const userTourBookings = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/user-tour-bookings`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  export const userTourBookedList = async (token) =>
  await axios.get(`${process.env.REACT_APP_API}/user-tour-booked-list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  export const userTourBooked = async (token, hotelId) =>
  await axios.get(`${process.env.REACT_APP_API}/user-tour-booked/${hotelId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  export const setQueueDownload = async (token, hotelId) =>
  await axios.get(`${process.env.REACT_APP_API}/tour-queue-download/${hotelId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  export const givePromo = async (token, hotelId, userEmail) =>
  await axios.get(`${process.env.REACT_APP_API}/tour/promo/${hotelId}/${userEmail}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });


export const searchListings = async (query) =>
  await axios.post(`${process.env.REACT_APP_API}/search-listings`, query);

  export const durationStringSeconds = (durat) => {
    let hours = Math.floor(durat * 1.0 / 60 / 60);
    let minutes = Math.floor((durat * 1.0) % (60 * 60) - (hours * 60 * 60));
    if (minutes < 10) minutes = "0" + minutes;
  
    return `${hours}h ${minutes}m`
  };
  
  export const durationStringMinutes = (duration) => {
    return `${Math.round(duration/60)}h ${Math.round(duration%60)}m`;
  };
  
  export const distanceString = (dist) => {
  return `${Math.round(dist*1000)}m / ${(Math.round(dist * 0.62137119 * 100) / 100).toFixed(2)}mi`    
};
    
export const storageTypeString = (sType) => {
  const sTypeVals = [
      "",
      "Walking, Urban",
      "Walking, Trail",
      "Walking, Historic",
      "Walking, Nature",
      "Accessible",
      "Biking, Urban",
      "Biking, Trail",
      "Biking, Historic",
      "Biking, Nature",
      "Water",
      "Skates, Hoverboard, Skateboard",
      "Electric Vehicle",
      "Power Vehicle"
  ];
  return (sTypeVals[sType * 1.0]);
};

export const addTour = async (token, data) => 
  await axios.post(
    `${process.env.REACT_APP_API}/upload-recorded`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );