import React, { useState } from "react";
import SitePublicAttachmentForm from "../cards/SitePublicAttachmentForm";
import { MapControl } from "../map/MapControl";
import { CheckCircleFilled } from "@ant-design/icons";

const TourPathEditForm = ({
  values,
  setValues,
  handleSubmit,
  handleChange
}) => {
  const { _id, 
    jroute,
  } = values;
  const startPoint = [jroute.path[0].La, jroute.path[0].Lo]; 

  const [zoom] = useState(14);
  const [minZoom] = useState(13);
  const [maxZoom] = useState(18);
  const [center] = useState(startPoint);
  const [selectedNode, setSelectedNode] = useState(-1);
  const [selectedNodeObj, setSelectedNodeObj] = useState(null);
  const [descTxt, setDescTxt] = useState("");


  const timeStampParse = function (stamp) {
    let ret = new Date(stamp);
    if (isNaN(ret)) {
      let stmp = stamp.replace(' ', 'T');
      ret = new Date(stmp);
    }
    if (isNaN(ret)) {
      ret = new Date("1970-01-01T" + stamp);
    }
    if (isNaN(ret)) {
      ret = new Date("1970-01-01T0" + stamp);
      if (!isNaN(ret)) {
        ret.setTime(ret.getTime() + 1000*60*60*12);
      }
    }
    if (isNaN(ret)) {
      ret = stamp;
    }
    return ret;
  }
  
  const formatTimestamp = function (d) {
    let curr_hour = d.getHours();
    curr_hour = curr_hour + "";    
    if (curr_hour.length === 1) {
        curr_hour = "0" + curr_hour;
    }    
    
    let curr_min = d.getMinutes();
    curr_min = curr_min + "";    
    if (curr_min.length === 1) {
       curr_min = "0" + curr_min;
    }    
    
    let curr_sec = d.getSeconds();
    curr_sec = curr_sec + "";    
    if (curr_sec.length === 1) {
        curr_sec = "0" + curr_sec;
    }    
    
    return curr_hour + ":" + curr_min + ":" + curr_sec;
  }
  
  const formatDate = (d) => {
    var curr_date = d.getDate();
    var curr_month = d.getMonth();
    curr_month++;
    var curr_year = d.getFullYear();
    return curr_month + "/" + curr_date + "/" + curr_year;
  }

  
  const handleClick = ({ event, latLng, pixel }) => {
    console.log('home page Map clicked!', latLng, pixel)
  }

  const handleContextClick = ({ event, latLng, pixel }) => {
      event.preventDefault();
      console.log('home page Map clicked Context!', latLng, pixel)
  }

  const handleMarkerClick = (event, payload, anchor) => {
      console.log(`Home page Marker #${payload} clicked at: `, anchor)
  }

  const handleMarkerContextClick = (event, payload, anchor) => {
      event.preventDefault();
      console.log(`Home page Marker context #${payload} clicked at: `, anchor)
  }

  const onPOITypeChange = (newIndex) => {
    if (selectedNodeObj) {
      console.log("here");
      selectedNodeObj.O = newIndex;
      setSelectedNodeObj(selectedNodeObj);
      setSelectedNode(selectedNode);
      setValues({ ...values, route: JSON.stringify(jroute) });
    }
  }

  const POITypes = [
    "Path",
    "Photos",
    "Rest Area",
    "Alert!",
    "Restroom",
    "Snacks",
    "Notes",
    "Shopping",
  ];

  const buildMarkers = (troute) => {
    if (troute && troute.path && troute.path.length > 0) {
      let route = troute;
      let marks = [];
      let i = 0;
      console.log(route);
      for (i = 0; i < route.path.length; i++) {
        let node = route.path[i];
        let pinNum = (i === selectedNode) ? 50 + node.O : node.O;
        const label = (node.O) ? "POI" : "Path";
        let pin = [label, [node.La, node.Lo], 10, `${process.env.PUBLIC_URL}/pins/${pinNum}s.png`, null, POITypes[node.O], i];

        marks.push(pin);
      }
      return marks;
    }
  }

  const buildPathlines = (troute) => {
    if (troute && troute.path && troute.path.length > 0) {
      let route = troute;
      let marks = [];
      let i = 0;
      for (i = 0; i < route.path.length; i++) {
        let node = route.path[i];
        let pline = [node.La, node.Lo]
        marks.push(pline);
      }
      return marks;
    }
  }

      ////////////// Click Drap Map Pins
    ///////////////////////////

    const onDragPinStart = (newAnchor, payload, anchor) => {
      console.log(`onDragPinStart #${payload} Mouse down at: `, newAnchor)
      setDescTxt((jroute.path[payload.key[6]].N) ? jroute.path[payload.key[6]].N : "");
      setSelectedNode(payload.key[6]);
    }

 
    const onDragPinEnd = (newAnchor, payload, anchor) => {
        payload.key[1] = newAnchor;

        console.log(`onDragPinEnd #${payload.key[6]} Mouse up at: `, newAnchor);
        console.log(`onDragPinEnd # Mouse up at: `, payload);
        let node = jroute.path[payload.key[6]];
        node.La = payload.key[1][0];
        node.Lo = payload.key[1][1];

        setDescTxt((jroute.path[payload.key[6]].N) ? jroute.path[payload.key[6]].N : "");
        setSelectedNode(payload.key[6]);
        setSelectedNodeObj(node);
        setValues({ ...values, route: JSON.stringify(jroute) });
    }

    const onDragPinMove = (newAnchor, payload, anchor) => {
    }

    const calcDeltaTimeStr = () => {
      let dl = calcDeltaTimeSec();
      let s = Math.floor(dl % 60);
      dl /= 60;
      let m = Math.floor(dl % 60);
      let h = Math.floor(dl / 60);
      return h + "h " + m + "m " + s + "s";
    }

    const calcDeltaTimeSec = () => {
      if (selectedNodeObj && selectedNodeObj.TS) {
        const wp1 = timeStampParse(selectedNodeObj.TS).getTime();
        const wp2 = timeStampParse(jroute.path[selectedNode + 1].TS).getTime();;
        const dl = Math.floor((wp2 - wp1) / 1000);   // seconds
        return dl;
      }
      return 0;
    }

    const onDescChange = (data) => {
      if (selectedNodeObj) {
        selectedNodeObj.N = data.currentTarget.value;
        setDescTxt(data.currentTarget.value);
        setValues({ ...values, route: JSON.stringify(jroute) });
      }
    }

    const onChangeTime = (data) => {
      if (selectedNodeObj && selectedNode < jroute.path.length - 1) {
        const curDelta = calcDeltaTimeSec();
        const realDelta = ((1.0*data.currentTarget.value) - curDelta) * 1000;
        
        let i = selectedNode + 1;
        for (; i < jroute.path.length; i++) {
          const curN = jroute.path[i];
          console.log('Time Step ', i);
          console.log(curN.TS);
          const nTime = timeStampParse(curN.TS).getTime() + realDelta;
          console.log(nTime);
          curN.TS = formatTimestamp(new Date(nTime));
          console.log(curN.TS);
        }
        setSelectedNodeObj(selectedNodeObj);
        setSelectedNode(selectedNode);
        setValues({ ...values, route: JSON.stringify(jroute) });
  //        setTimeTxt(data.currentTarget.value);
      }
    }

    const insertNode = (curNodeIdx) => {
      if (curNodeIdx > 0) {
        let node1 = jroute.path[curNodeIdx - 1];
        let node2 = jroute.path[curNodeIdx];
        const t1 = timeStampParse(node1.TS).getTime();
        const t2 = timeStampParse(node2.TS).getTime();
        const tn = ((t2 + t1) / 2);
        let nodeN = {
          O: 0,
          La: ((node2.La + node1.La) / 2),
          Lo: ((node2.Lo + node1.Lo) / 2),
          A: ((node2.A + node1.A) / 2),
          D: node2.D,
          TS: formatTimestamp(new Date(tn))
        };
        jroute.path.splice(curNodeIdx, 0, nodeN);
        setSelectedNodeObj(jroute.path[curNodeIdx]);
        setSelectedNode(curNodeIdx);
        setValues({ ...values, route: JSON.stringify(jroute) });
      }
    }

    const onAddPreNode = () => {
      if (selectedNode > 0) {
        insertNode(selectedNode);
      }
    }

    const onAddPostNode = () => {
      if (selectedNode < jroute.path.length - 1) {
        insertNode(selectedNode + 1);
      }
    }

    const CalcDist2Points = (lat1, lon1, lat2, lon2, isMetric) => {    // return miles or meters.
      let dist = 0;
    if ((lat1 !== lat2) || (lon1 !== lon2)) {
      const radlat1 = Math.PI * lat1/180;
      const radlat2 = Math.PI * lat2/180;
      const theta = lon1-lon2;
      const radtheta = Math.PI * theta/180;
      dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      if (isMetric) { 
              dist = dist * 1.609344 * 1000;  // meters
  //        } else {             
  //            dist = dist * 5280; // feet
          }
    }
      return dist;
  }
  

  
    const saveChanges = (e) => {
      let currentDate = new Date();
      jroute.lastUpdate = `${formatTimestamp(currentDate)} ${formatDate(currentDate)}`;

      jroute.t = jroute.b = jroute.path[0].La;
      jroute.l = jroute.r = jroute.path[0].Lo;
    
      let lastpt = jroute.path[0];    
      jroute.dist = 0;
      let i = 0;
      for (i = 1; i < jroute.path.length; i++) {
        const pt = jroute.path[i];
        // top/bottom
        if (pt.La > jroute.t) {
          jroute.t = pt.La
        }    
        if (pt.La < jroute.b) {
          jroute.b = pt.La
        }    
        if (pt.Lo > jroute.r) {
          jroute.r = pt.Lo
        }    
        if (pt.Lo < jroute.l) {
          jroute.l = pt.Lo
        }    
        jroute.dist += CalcDist2Points(pt.La, pt.Lo, lastpt.La, lastpt.Lo);
        lastpt = pt;
      }

      let wp1 = timeStampParse(jroute.path[0].TS);
      let wp2 = timeStampParse(jroute.path[jroute.path.length-1].TS);
      console.log ("Time start", wp1);
      console.log ("Time end", wp2);
      let dl = Math.floor((wp2 - wp1) / 1000 / 60);
      console.log ("Time delta", dl);

      handleChange({target: {"name": "duration", "value": dl}});
      handleChange({target: {"name": "distance", "value": jroute.dist}});
      jroute.duration = dl;
      setValues({ ...values, distance: jroute.dist, duration: jroute.duration, route: JSON.stringify(jroute) });
      setValues({ ...values, distance: jroute.dist, duration: jroute.duration});
      handleSubmit(e);
    }

    const nodeImage = (e) => {
      console.log(`Selected Image`, e);
      console.log(`Selected Image = ${e.target.id}`);
      selectedNodeObj.P = e.target.id;
      setSelectedNodeObj(selectedNodeObj);
      setSelectedNode(selectedNode);
      setValues({ ...values, route: JSON.stringify(jroute) });
    }

    const pinButton = (myIndex, selectedIndex) => {
      return (
      <button onClick={() => onPOITypeChange(myIndex)} className={(selectedIndex === myIndex) ? "btn btn-outline-primary m-2" : "btn m-2"} style={{width:'22%'}} tick={(selectedIndex === myIndex)} >
      <h7>
      <img style={{position:"absolute", height:"1.8em", margin:"-3px 0 0 -40px"}} alt="attachment" src={`/pins/${myIndex}.png`} width='24px' />
        {POITypes[myIndex]}{(selectedIndex === myIndex) ? (<CheckCircleFilled style={{position:"absolute", height:"2em", margin:"3px 0 0 6px"}} />) : ""}
      </h7>
    </button>
      );
    }

  
  return ( 
    <>
      <p>
        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <div style={{ maxWidth: 1000, width: 1000, margin: '0 auto' }}>
            <MapControl
                onClick={handleClick}
                onContextMenu={handleContextClick}
                onVehicleClick={handleMarkerClick}
                onMarkerClick={handleMarkerClick}
                onMarkerContextClick={handleMarkerContextClick}
                onDragPinMove={onDragPinMove} 
                onDragPinStart={onDragPinStart} 
                onDragPinEnd={onDragPinEnd} 
                center={center}
                zoom={zoom}
                dprs={[1, 2]}
                minZoom={minZoom}
                markers={buildMarkers(jroute)}
                pathlines={buildPathlines(jroute)}
                maxZoom={maxZoom}
                defaultWidth={1000}
                defaultHeight={500}
                height={500}
                boxClassname="pigeon-filters"
                vehicles={[]}>
            </MapControl>
          </div>

          <label className="select">
            { (selectedNodeObj != null) ? (
            <>
              <button className="btn btn-primary" disabled={(selectedNode <= 0)} onClick={onAddPreNode} >Split before</button>&nbsp;
              <button className="btn btn-primary" disabled={((selectedNode < 0) || (jroute && jroute.path && selectedNode >= jroute.path.length - 1))} onClick={onAddPostNode} >Split after</button>
              <br />
              <div>
              {pinButton(0, selectedNodeObj.O)}
              {pinButton(1, selectedNodeObj.O)}
              {pinButton(2, selectedNodeObj.O)}
              {pinButton(3, selectedNodeObj.O)}
              {pinButton(4, selectedNodeObj.O)}
              {pinButton(5, selectedNodeObj.O)}
              {pinButton(6, selectedNodeObj.O)}
              {pinButton(7, selectedNodeObj.O)}
              </div>

              <textarea
                name="N"
                onChange={onDescChange}
                placeholder="Enter Description"
                className="form-control m-2"
                rows="5" cols="60" 
                value={descTxt}
              />
              { (selectedNode !== jroute.path.length - 1) ? <span className="form-control m-2">Time to next {calcDeltaTimeStr()} 
              <input value={calcDeltaTimeSec()} onChange={onChangeTime} type="number" min="2" className="form-control m-2"></input>
              </span> : ""}
              <SitePublicAttachmentForm key={_id} imageMode="1" selectedImg={selectedNodeObj.P} selectedObj={selectedNodeObj} onSelect={nodeImage} h={_id} ></SitePublicAttachmentForm>

            </>) : ""
            }
          </label>
        </div>
      </p>
      <button onClick={saveChanges} className="btn btn-outline-primary m-2">Save</button>
    </>  
  );
};

export default TourPathEditForm;
