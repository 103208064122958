import { Tag } from 'antd';
  
  export const TypeFeatures = (hotl) => {
    return ( <><> </>
      { (hotl.isEasy) ? (<Tag color="green">Easy</Tag>) : (<>
        { (hotl.isModerate) ? (<Tag color="yellow">Moderate</Tag>) : (<>
          { (hotl.isStrenuous) ? (<Tag color="red">Strenuous</Tag>) : (<></>) }
        </>) }
      </>) }
      { (hotl.isLandmark) ? (<Tag color="magenta">Landmarks</Tag>) : (<></>) }
      { (hotl.isHistory) ? (<Tag color="cyan">History & Culture</Tag>) : (<></>) }
      { (hotl.isNature) ? (<Tag color="purple">Nature</Tag>) : (<></>) }
      { (hotl.isWaterAct) ? (<Tag color="blue">Water Activities</Tag>) : (<></>) }
      { (hotl.isSustainable) ? (<Tag color="gray">Sustainable</Tag>) : (<></>) }
      { (hotl.isShopping) ? (<Tag color="geekblue">Shopping</Tag>) : (<></>) }
      { (hotl.hasRestrooms) ? (<Tag color="lime">Restrooms Marked</Tag>) : (<></>) }
      { (hotl.hasRefreshments) ? (<Tag color="orange">Refreshments Marked</Tag>) : (<></>) }
      </> )
  };   
  