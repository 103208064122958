import React, { useState, useEffect } from "react";
import { Alert, Image, Rate } from 'antd';
import { read, userTourBooked } from "../actions/hotel";
import { storageTypeString, durationStringMinutes, distanceString } from "../actions/hotel";
import { getSessionId, purchaseForFree } from "../actions/stripe";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { TypeFeatures } from "../components/Helper";
import { MapControl } from '../components/map/MapControl'
import { currencyFormatter } from "../actions/stripe";
import { PictureOutlined } from "@ant-design/icons";
import { createReview, readMyReview } from "../actions/review";
import { toast } from "react-toastify";
import ReviewListModal from "../components/modals/ReviewListModal";


const ViewSite = ({ match, history }) => {
  const [site, setSite] = useState({});
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasBought, setHasBought] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [zoom] = useState(13);
  const [minZoom] = useState(3);
  const [maxZoom] = useState(14);
  const [center, setCenter] = useState([25.086031275261004, -77.3540352218439]);
  const [markers, setMarkers] = useState([]);
  const [pathlines] = useState([]);
  const [review, setReview] = useState({description: "", anon: false, stars: 0});

  const handleClick = ({ event, latLng, pixel }) => {
    console.log('home page Map clicked!', latLng, pixel)
  }

  const handleContextClick = ({ event, latLng, pixel }) => {
      event.preventDefault();
      console.log('home page Map clicked Context!', latLng, pixel)
  }

  const handleMarkerClick = (event, payload, anchor) => {
      console.log(`Home page Marker #${payload} clicked at: `, anchor)
  }


  const handleMarkerContextClick = (event, payload, anchor) => {
    event.preventDefault();
    console.log(`Home page Marker context #${payload} clicked at: `, anchor)
  }

  const handleNullHandler = (event, payload, anchor) => {
  }

  const desc = ['No rating', 'Inaccurate', 'Poorly planned', "It's OK", 'This is good', 'Absolutely loved it'];
  const desctips = ['Inaccurate', 'Poorly planned', "It's OK", 'This is good', 'Absolutely loved it'];
  const changeRating = (event) => {
    console.log(event);
    setReview({...review, stars:event})
  }  
  const handleChange = (event) => {
    console.log(event);
    if(event.target.value.length < 500)
      setReview({...review, description:event.target.value})
  }  
  const handleRatingClick = async ({ event }) => {
    let siteData = new FormData();
    siteData.append("description", review.description);
    siteData.append("anon", false);
    siteData.append("emoji", 0);
    siteData.append("stars", review.stars);
    siteData.append("siteId", site._id);
    
    try {
      const res = await createReview(auth.token, siteData)
      console.log("Attach result");
      console.log(res);
      console.log(res.data._id);
      toast.success("New review submitted");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log(err); 
      console.log(err.response.data); 
      toast.error(err.response.data);
    }
  }

  const { auth } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadSellerSite();
    // eslint-disable-next-line
  }, []);

  let siteData = null;

  useEffect(() => {
    if (auth && auth.token) {
      loadSiteBought();
      }
    return () => {
      console.log("**************************************************************************");
    };
    // eslint-disable-next-line
  }, []);

  const buildMarkers = (troute) => {
    let route = JSON.parse(troute);
    let marks = [];
    let i = 0;
    for (i = 0; i < route.path.length; i++) {
      let node = route.path[i];
      if (node.O * 1.0 !== 0) {
        let pin = ["POI", [node.La, node.Lo], 10, `${process.env.PUBLIC_URL}/pins/${node.O}s.png`, null, "POI", i];

        marks.push(pin);
      }

    }
    setMarkers(marks);
    console.log(route);
  }

  const loadSiteBought = async () => {
    let res2 = await userTourBooked(auth.token, match.params.hotelId);
    if (res2.data.length) {
      console.log("setHasBought(true) *******************************************");
      setHasBought(true);
    }
    console.log(res2);  
    let res = await readMyReview(auth.token, match.params.hotelId);
    console.log("getReview", res);
    if (res.data.length) {
      console.log("setReview", res.data[0]);
      setReview(res.data[0]);
    }
    console.log(res );  
};

  const loadSellerSite = async () => {
    if (match && match.params && match.params.hotelId) {
      let res = await read(match.params.hotelId);
      // console.log(res);
      setSite(res.data);
      siteData = res.data;
      setCenter([siteData.geoLocation.coordinates[1],siteData.geoLocation.coordinates[0]]);
      buildMarkers(siteData.route);
      setImage(`${process.env.REACT_APP_API}/tour/image/${siteData._id}`);
      setImage2(`${process.env.REACT_APP_API}/tour/image2/${siteData._id}`);
      setImage3(`${process.env.REACT_APP_API}/tour/image3/${siteData._id}`);
    }
  };

  const handleBookClick = async (e) => {
    e.preventDefault();

    if (!auth || !auth.token) {
      history.push("/login");
      return;
    }

    setLoading(true);
    if (!auth) history.push("/login");

    if (site.postedBy && site.postedBy.banned) {
      history.push("/login");
    }
  
    if (auth.user.banned) {
      history.push("/login");
    }
  

    //     console.log(auth.token, match.params.hotelId);
    if (site.price === 0) {
      await purchaseForFree(auth.token, match.params.hotelId);
      window.location.reload(false);
    } else {
      console.log("getting sessionid  ===>");
      let res = await getSessionId(auth.token, match.params.hotelId);
      console.log("get sessionid resposne", res.data.sessionId);
      console.log("STRIPE_SECRET ==> ", process.env.REACT_APP_STRIPE_KEY);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      stripe
        .redirectToCheckout({
          sessionId: res.data.sessionId,
        })
        .then((result) => console.log(result));
    }
  };


//  console.log(site.postedBy);
//  console.log(auth.user);
  return (
    <>
      <div style={{backgroundImage:"url(/backgrounds/viewsite.jpg)", backgroundSize:"100% 110%"}}  className="container-fluid bg-secondary p-5 text-center">
        <h1 className="page-title">{site.title}</h1>
        <h4 >{site.location}</h4>
            <p>  {TypeFeatures(site)}</p>
              <h5>{storageTypeString(site.layout)}</h5>

      </div>
      {showModal && (
          <ReviewListModal
            id={site._id}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <br />
            {site.image && site.image.contentType ? (
              <Image src={image} alt={site.title} className="img img-fluid m-2" />
            ) : (<img
              src="https://via.placeholder.com/900x500.png?text=stashling.com"
              alt="default site"
              className="card-image img img-fluid"
            />)}          

            {site.image2 && site.image2.contentType ? (
              <Image
                src={image2}
                alt="site"
                className="card-image img img-fluid"
              />
              ) : ( <></> )}          
              {site.image3 && site.image3.contentType ? (
              <Image
                src={image3}
                alt="site"
                className="card-image img img-fluid"
              />
            ) : ( <></> )}          
          </div>
          <div className="col-md-6">
          <br />
              <br />
            <b>{site.content}</b>
            <p className="alert alert-info mt-3">Tour takes <span className="float-right text-primary">
                  {durationStringMinutes(site.duration)}{" "}
                </span>
                and spans <span className="float-right text-primary">
                  {distanceString(site.distance)}{" "}
                </span>
            </p>
            <div>
              <div style={{ textAlign: 'center', marginTop: 50 }}>
                <div style={{ maxWidth: 600, width: 600, margin: '0 auto' }}>
                  <MapControl
                      onClick={handleClick}
                      onContextMenu={handleContextClick}
                      onVehicleClick={handleMarkerClick}
                      onMarkerClick={handleMarkerClick}
                      onMarkerContextClick={handleMarkerContextClick}
                      onDragPinMove={handleNullHandler} 
                      onDragPinStart={handleNullHandler} 
                      onDragPinEnd={handleNullHandler} 
                      center={center}
                      zoom={zoom}
                      dprs={[1, 2]}
                      minZoom={minZoom}
                      markers={markers}
                      pathlines={pathlines}
                      maxZoom={maxZoom}
                      defaultWidth={600}
                      defaultHeight={600}
                      height={400}
                      boxClassname="pigeon-filters"
                      vehicles={[]}>
                  </MapControl>
                </div>
              </div>
            </div>
            <br />
            <p>{ (site.bookedCount && !hasBought) ? (`Purchased ${site.bookedCount} ${ (site.bookedCount === 1) ? "time" : "times" }`) : ""}</p>

            { auth && auth.user && site && site.postedBy && auth.user._id === site.postedBy._id ?  (
            <>
              <i>Your posting</i><br/>
              <i>Posted on:  {`${site.createdAt ? new Date(site.createdAt).toLocaleString() : ""}`}</i><br/>
              <i>Updated on:  {`${site.updatedAt ? new Date(site.updatedAt).toLocaleString() : ""}`}</i><br /><br />
              {site.rating > 0 ? ( <>
                <Rate disabled count={5} value={site.rating} character={<PictureOutlined />} style={{ fontSize: 20 }} allowHalf />
                              <span >&nbsp;{desc[Math.ceil(site.rating)]}&nbsp;</span>&nbsp;
                              <button onClick={() => {setShowModal(true)}} className="btn btn-sm btn-primary">Show {site.numRatings} Reviews</button><br /><br />
                </>) : <>No one has rated this tour yet</>}
              </>
            ) : ( <>
              <i>Posted by {site.postedBy && site.postedBy.name}</i><br/>
              {(site.postedBy && site.postedBy.banned) ? (<i>This user has been locked out<br/></i>) : (<></>)}
              <i>Last Updated on:  {`${site.createdAt ? new Date(site.updatedAt).toLocaleString() : ""}`}</i><br/><br/>

              {site.rating > 0 && ( <>
                <Rate disabled count={5} value={site.rating} character={<PictureOutlined />} style={{ fontSize: 20 }} allowHalf />
                              <span >&nbsp;{desc[Math.ceil(site.rating)]}&nbsp;</span>&nbsp;
                              <button onClick={() => {setShowModal(true)}} className="btn btn-sm btn-primary">Show {site.numRatings} Reviews</button><br /><br />
              </>)}
              {hasBought ? ( <>
                Your Rating:&nbsp;&nbsp;
                <Rate onChange={(value) => changeRating(value)} count={5} value={review.stars} character={<PictureOutlined />} tooltips={desctips}  style={{ fontSize: 36 }} allowHalf />
                <span >&nbsp;{desc[Math.ceil(review.stars)]}</span>
                <br />
                <textarea
                  name="content"
                  onChange={handleChange}
                  placeholder="Review"
                  className="form-control m-2"
                  value={review.description}
                />
                <p style={{width:"99%", height:"0em", textAlign:"right"}}>{review.description.length} of 500 characters</p>
                <button
                  onClick={handleRatingClick}
                  className="btn btn-block btn-sm btn-primary mt-3"
                  disabled={loading}
                >
                  Sumbit Review
                </button>                
                <br /><br />
                <Alert  style={{margin:"0 0 1em 0"}}  type="success" message="You already have access to this Walking Tour" />
                </>
              ) : (<button
                onClick={handleBookClick}
                className="btn btn-block btn-lg btn-primary mt-3"
                disabled={loading}
              >
                {loading
                  ? "Loading..."
                  : auth && auth.token
                  ? `Buy Now (${currencyFormatter({
                    amount: site.price * 100,
                    currency: "usd",
                  })})`
                  : "Login to Book"}
              </button>)}
            </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSite;
