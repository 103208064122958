import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Avatar, Badge } from "antd";
import moment from "moment";
import {
  getAccountBalance,
  currencyFormatter,
  payoutSetting,
} from "../actions/stripe";
import { SettingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const { Meta } = Card;
const { Ribbon } = Badge;

const ConnectNav = () => {
  const [balance, setBalance] = useState(0);
  const { auth } = useSelector((state) => ({ ...state }));
  const { user, token } = auth;

  useEffect(() => {
    getAccountBalance(auth.token).then((res) => {
      setBalance(res.data);
    });
    // eslint-disable-next-line
  }, []);

  const handlePayoutSettings = async () => {
    try {
      const res = await payoutSetting(token);
      // console.log("RES FOR PAYOUT SETTING LINK", res);
      window.location.href = res.data.url;
    } catch (err) {
      console.log(err);
      toast("Unable to access settings. Try again");
    }
  };

  return (
    <div className="d-flex justify-content-around">
      <Link to="/settings" >
        <Card className="user-card">
          <Meta
            avatar={<><SettingOutlined className="h5 pt-2" /><Avatar>{user.name[0]}</Avatar></>}
            title={user.name}
            description={`Joined ${moment(user.createdAt).fromNow()}`}
            emojis="true"
          />
        </Card>
      </Link>
      {auth &&
        auth.user &&
        auth.user.stripe_seller &&
        auth.user.stripe_seller.charges_enabled && (
          <>
            <Ribbon text="Avaliable" color="grey">
              <Card className="bg-light pt-1">
                <span className="lead" >
                {balance &&
                  balance.pending &&
                  balance.pending.map((bp, i) => (
                    <span key={i}>
                      {currencyFormatter(bp, true)}
                    </span>
                  ))}
                </span>
              </Card>
            </Ribbon>
            <Ribbon text="Payouts" color="silver">
              <Card onClick={handlePayoutSettings} className="bg-light pointer">
                <SettingOutlined className="h5 pt-2" />
              </Card>
            </Ribbon>
          </>
        )}
    </div>
  );
};

export default ConnectNav;
